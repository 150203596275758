<template>
   <div>
    <v-banner
        class="lavBanner" 
        single-line
        height="400"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/IPHome-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  &nbsp;&nbsp;&nbsp;对接全球IP，赋能品牌商业价值
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  多种类型的IP资源与产品、销售、运营等多方优质资源整合，实现多方资源绑定，成果持续共享
                </v-card-title>
                <div class="d-flex justify-center mt-5">
                  <v-btn
                    width="160"
                    class="font-size-20 rounded-pill"
                    color="#0084ff"
                    style="color: #fff"
                    @click="dialog=true"
                  >
                    立即咨询
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-dialog 
      v-model="dialog"
      width='550'
    >
      <v-card color="transparent" flat class="transparent_card position-r">
        <v-card-title class="close-btn" @click.stop="dialog = false">x</v-card-title>
        <v-card-title class="font-size-24 justify-center" >立即咨询 </v-card-title>
        <div class="box">
          <div class="box-ewm">
            <v-img src='https://h5.ophyer.cn/official_website/other/ar-ip.png' width="200" height="200" style="margin: 0 auto;" contin></v-img>
            <v-card-text class="text-align-center font-size-14 pa-0 color-666" style="margin-top: -3px">扫一扫加我微信</v-card-text>
          </div>
          <div class="box-row">
            <v-row class="pa-0 mt-8">
              <div class="d-inline width-60 ml-7" style="margin-top: -10px;">
                <v-avatar size="50" rounded-2>
                  <v-img contain src="https://h5.ophyer.cn/official_website/other/ar-diloag-icon1.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mt-n3">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-18">
                    赵总
                  </v-card-title>
                  <v-card-text class="pa-0  font-size-14">18813090558</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-5">
              <div class="d-inline width-60 ml-7">
                <v-avatar size="50" rounded-2>
                  <v-img contain src="https://h5.ophyer.cn/official_website/other/ar-diloag-icon2.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-18">
                    邮箱
                  </v-card-title>
                  <v-card-text class="pa-0 font-size-14">zhaoxiaozhi9@163.com</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-5">
              <div class="d-inline width-60 ml-7">
                <v-avatar size="50" rounded-2>
                  <v-img contain src="https://h5.ophyer.cn/official_website/other/ar-diloag-icon3.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mb-8">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-18">
                    地址
                  </v-card-title>
                  <v-card-text class="pa-0 font-size-14">北京市朝阳区竞园商八</v-card-text>
                </v-card>
              </div>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <div class="first">
       <div class="public-title">
        <p>ALL AREAS</p>
        <div>
          <span>全领域IP一站展示</span>
          <span>明星IP、游戏IP、影视IP、文旅IP等多种类型的IP资源，一对一签约合作</span>
        </div>
      </div>
       <v-tabs  centered  v-model="tab" fixed-tabs  hide-slider class="new-tabs-two">
          <v-tab v-for="(item,ind) in tablist" :key="ind">
            <b>{{ item.tab }}</b>
          </v-tab>
       </v-tabs>
      <v-tabs-items v-model="tab" >
       <v-tab-item
        v-for="(item,ind) in tabitem"
        :key="ind"
      >
        <v-container fluid >
          <v-row >
            <v-col v-for="content in item.content" :key="content.id" :cols="ind==0?'3':4">       
                <v-card class="mx-auto item-card justify-space-between" max-width="240" max-height="320" v-if='ind==0'>     
                  <v-img
                    max-height="320"
                    :src="content.img"
                    :aspect-ratio="9/16"
                  >
                  </v-img>
                </v-card>
                <v-card class="mx-auto" flat max-width="300" max-height="325" v-if='ind!=0' style="text-align:left" @click="goCaseDetail(content)">     
                  <v-img
                    max-height="200"
                    :src="content.img"
                    :aspect-ratio="9/16"
                    style="border-radius: 10px;"
                  >
                  </v-img>
                  <v-card-title class="font-size-20 family-Bold mt-1">{{content.title}}</v-card-title>
                  <v-card-text class="font-size-14 family-normal subtitle-1 align-left" height='87'>{{content.sub}}</v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- 万景云产品方案 -->
    <v-card class="second" :flat="true">
      <div class="public-title">
        <p>APPLICATION ACENARIO</p>
        <div>
          <span>应用场景展示</span>
          <span>广泛的应用场景，赋能商业，打造差异化品牌IP</span>
        </div>
      </div>
      <div class="con">
        <v-carousel class="carousel-new" height="400" hide-delimiters :reverse="false" show-arrows-on-hover :cycle="true">
          <v-carousel-item
            v-for="(v, k) in Pro_list"
            :key="k"
          >
            <div class="box">
              <img :src="v.img" alt="" />
              <div>
                <p style="color:#333">{{ v.title }}</p>
                <p ></p>
                <p v-html='v.content' style="color:#666">{{ v.content }}</p>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-card>
    <!-- 行业态势 -->
    <div class="third">
       <div class="public-title">
          <p>PRODUCT ADVANTAGES</p>
          <div>
            <span>行业态势</span>
            <span>数字创意产业崛起，内容为王的时代，得IP者得天下</span>
           </div>
         </div>
       <v-card color="" flat class="third_card pa-2 mb-12" >
         <v-row  class="justify-center" align='center'>
           <v-col v-for='(item,ind) in ProductCard' :key='ind' cols='3'>
             <v-img :src='item.icon' width='120' style="top:38px;margin:-20px auto;z-index: 99"></v-img>
             <v-card height='300px' width="240" class="ProductCard" flat>
               <v-img :src='item.img' height='300px' aspect-ratio="1.4" >
                <v-card-title class="font-size-24 font-weight-bold justify-center mt-11">{{item.title}}</v-card-title>
                <v-card-text class="font-size-14" style="color:#666">{{item.content}}</v-card-text>
               </v-img>
             </v-card>
           </v-col>
         </v-row>
       </v-card>
    </div>
    <!-- 案例展示 -->
      <!-- <v-container fluid style="background: #F5F9FD;padding-top:1px" class="mt-10 pb-10" >
        <div class="public-title">
          <p> CASE SHOW</p>
          <div>
            <span>客户案例</span>
           </div>
         </div>
          <v-row class="case_row">
            <v-col v-for="item in cardslist" :key="item.id" cols="3">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="mx-auto item-card" max-width="240" height="420">
                  <v-overlay
                    v-if="hover"
                    absolute
                    :opacity="0.8"
                    color="#000"
                  >
                    <v-card class="hover-card">
                      <div class="d-flex justify-center">
                        <div class="d-flex justify-center py-2" style="background:#FFF;width:170px;margin-top:55px">
                          <v-img :aspect-ratio="1" class="flex-grow-0" alt="二维码" width="160" height="160" contain src="@/assets/login/qrcode.png"></v-img>
                        </div>
                      </div>
                      <v-card-text class="sm white--text font-size-16" style='text-align:center'>
                        扫码体验
                      </v-card-text>
                    </v-card>
                    <v-card-text class="font-size-14 white--text mt-8" v-text="item.content"></v-card-text>
                  </v-overlay>
                  <v-img
                    max-height="340"
                    :src="item.src"
                    :aspect-ratio="9/16"
                  >
                  </v-img>
                  <div class="warp-icon">
                    <v-img :aspect-ratio="1" class="flex-grow-0" alt="二维码" width="50" height="50" contain src="@/assets/login/qrcode.png"></v-img>
                  </div>
                  <div class="text-box mt-5
                  " >
                    <v-card-text class=" font-size-14 " v-text="item.content" height='90'></v-card-text>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
      </v-container> -->
   </div>
</template>

<script>
import qs from 'qs'
import FanDate from '../../static/FanData.js'
export default {
  name: "IPhome",
  data(){
    return{
      model: 0,
      tab:null,
      windowHight: window.innerHeight/2,
      bannerLoad: false,
      imgLoad: false,
      tablist:FanDate.IP.tablist,
      tabitem:FanDate.IP.tabitem,
      list:FanDate.IP.list,
      cardslist:FanDate.IP.cardslist,
      ProductCard:FanDate.IP.ProductCard,
      Pro_list:FanDate.IP.Pro_list,
      dialog:false
    }
  },
   mounted(){
    // var _this = this;
    this.bannerLoad = true;

  },
  methods: {
    handleLoad(){
      this.imgLoad = true;
      console.log("ewf")
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.anli
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>
// ::v-deep .v-card__text,.v-card__title {
//   line-height: 20px !important;
//   padding:19px 0px 0px 0px!important;
// }
::v-deep .v-dialog{
  background: linear-gradient(180deg, #FFFFFF, #fff);
  border: 2px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0px 10px 20px 0px rgba(68, 148, 255, 0.1);
  border-radius: 10px;
  .close-btn {
    position: absolute;
    right: -12px;
    top: -10px;
    cursor: pointer;
  }
  .box {
    margin: 25px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-ewm {
    text-align: center;
  }
}
::v-deep .transparent_card{
  .v-card__text{
    line-height: 26px !important;
  }
  .v-card__title{
    line-height: 26px !important;
  }
}
::v-deep .v-card__subtitle, .text-subtitle-1{
  line-height: 20px !important;
  padding:1px 6px 6px 0;
}
.new-tabs-two .v-tab--active {
    color: #0568FD!important;
}
.new-tabs-two .v-tab {
    height: 50px !important;
}
.new-tabs-two .v-tabs-bar__content{
  padding: 0 !important;
}
::v-deep .v-dialog{
    // background: linear-gradient(180deg, #FFFFFF, #CCE2FF);
    // border: 2px solid rgba(255, 255, 255, 0.6);
    // box-shadow: 0px 10px 20px 0px rgba(68, 148, 255, 0.1);
    border-radius: 10px;
    background: white;
}
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}

.content{
    padding: 24% 14% 3% 14%;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    background: url(/img/bck.18dc8ae9.png) no-repeat center;
    background-size: cover;
    h2{
      font-size: 34px;
      margin-bottom: 20px;
    }
}
::v-deep .theme--light.v-tabs-items{
  max-width: 1120px;
  margin: 16px auto;
}
::v-deep .item-card {
    margin-bottom: 15px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    border-radius: 8px;
    position: relative;
    .warp-icon {
      position: absolute;
      right: 10px;
      bottom: 72px;
      width: 50px;
      height: 50px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
.first{
  text-align: center;
  margin: 0 auto;
  max-width: 1300px;
  margin: 0 auto;
  .public-title{
     margin-bottom: 15px !important;
  }
  .v-card--link:focus:before{
    // opacity: 0;
  }
}
 .second{
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  .con {
    max-width: 1200px;
    margin: 40px auto;
    text-align: start;
  }
  .box {
    padding: 20px 180px;
    height: 320px;
    
    margin-top: 39px;
    // cursor: pointer;
    background: #f5f9fd;
    border-radius: 30px;
    img {
      position: absolute;
      top: 0px;
      // width: 260px;
      // height: 260px;
      width: 400px;
      border-radius: 30px;
    }
    div {
      
      padding-left: 442px;
      p:nth-of-type(1) {
        
        margin-top: 78px;
        margin-bottom: 10px;
        font-size: 24px;
        //font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        // color: #666;
      }
      p:nth-of-type(2) {
        margin-bottom: 20px;
        font-size: 20px;
        //font-family: SourceHanSansCN-Normal;
        font-weight: 400;
        white-space:nowrap;
        // color: #666;
      }
      p:nth-of-type(3) {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
.third{
  // margin: 60px auto;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 70px;
  .v-card__text,.v-card__title {
    line-height: 20px !important;
    padding:19px 15px 0px 15px!important;
  }
  .ProductCard{
    background: rgba(206, 206, 206, 0);
    border: 1px solid #E9E9E9;
    border-radius: 30px 30px 0px 0px;
    margin: 0 auto;
    &:hover{
      border: 1px solid #BED3FF;
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
  }
  .third_card{
    margin: 0 auto;
  }
  .card_title{
    padding: 87px 10px 30px 10px
  }
}

::v-deep .v-btn--fab.v-size--x-large{
    width: 110px;
    top: 54px;
    z-index: 555;
    height: 110px;
}
::v-deep .v-carousel__controls{
  background:white
}
.case_row{
  max-width: 1200px;
  margin: 0 auto;
}
::v-deep .mx-auto:focus {
  display: none !important;
}
</style>